import {MittPlugin} from "@/shared/plugins"
import {OutsideClick} from "@/assets/js/frontend-v3/directives/OutsideClick.ts";
import axios from 'axios';
import InlineSvg from 'vue-inline-svg';
import {createApp, defineAsyncComponent} from "vue";
// import './scripts/replaceWebP.js';
import './scripts/supportsPassive.js';
import '../backoffice/components/date.js';

/** Define components that should be loaded dynamically **/
const components = {

    //temp
    // 'rebranding-event-handler': defineAsyncComponent(() => import('./website/components/General/RebrandingEventHandler.vue')),

    //general
    'cookie-policy': defineAsyncComponent(() => import('./website/components/General/CookiePolicy.vue')),
    'debug-window': defineAsyncComponent(() => import('./website/components/General/InternalInfoBar/DebugWindow.vue')),
    'event-handler': defineAsyncComponent(() => import('./website/components/General/EventHandler.vue')),
    'flash-message': defineAsyncComponent(() => import('./website/components/General/FlashMessage.vue')),
    'loader-overlay-logo': defineAsyncComponent(() => import('./website/components/General/LoaderOverlayLogo.vue')),
    'language-picker': defineAsyncComponent(() => import('./website/components/General/LanguagePicker.vue')),
    'read-more-button': defineAsyncComponent(() => import('./website/components/General/ReadMoreButton.vue')),
    'scroll-to-top': defineAsyncComponent(() => import('./website/components/General/ScrollToTop.vue')),
    'tooltip': defineAsyncComponent(() => import('./website/components/General/ToolTip.vue')),
    'view-renderer': defineAsyncComponent(() => import('./website/components/ViewRenderer.vue')),

    //form
    'custom-form': defineAsyncComponent(() => import('./website/components/Form/CustomForm.vue')),
    'form-group': defineAsyncComponent(() => import('./website/components/Form/Group.vue')),
    'form-group-checkbox': defineAsyncComponent(() => import('./website/components/Form/GroupCheckbox.vue')),
    'form-group-date': defineAsyncComponent(() => import('./website/components/Form/GroupDate.vue')),
    'form-group-file': defineAsyncComponent(() => import('./website/components/Form/GroupFile.vue')),
    'form-group-plain': defineAsyncComponent(() => import('./website/components/Form/GroupPlain.vue')),
    'form-group-select': defineAsyncComponent(() => import('./website/components/Form/GroupSelect.vue')),
    'form-group-text': defineAsyncComponent(() => import('./website/components/Form/GroupText.vue')),
    'form-group-textarea': defineAsyncComponent(() => import('./website/components/Form/GroupTextarea.vue')),
    'form-group-toggle': defineAsyncComponent(() => import('./website/components/Form/GroupToggle.vue')),
    'validation-row': defineAsyncComponent(() => import('./website/components/Form/ValidationRow.vue')),

    //popup
    'popup-generator': defineAsyncComponent(() => import('./website/components/Popups/PopupGenerator.vue')),
    'popup-with-content': defineAsyncComponent(() => import('./website/components/Popups/PopupWithContent.vue')),
    'popup-for-content': defineAsyncComponent(() => import('./website/components/Popups/PopupForContent.vue')),

    //swiper
    'swiper-basic': defineAsyncComponent(() => import('./website/components/Swiper/SwiperBasic.vue')),
    'swiper-basic-images': defineAsyncComponent(() => import('./website/components/Swiper/SwiperBasicImages.vue')),
    'swiper-offices': defineAsyncComponent(() => import('./website/components/Swiper/SwiperOffices.vue')),
    'swiper-testimonials': defineAsyncComponent(() => import('./website/components/Swiper/SwiperTestimonials.vue')),
    // 'swiper-test': defineAsyncComponent(() => import('./website/components/Swiper/SwiperTest.vue')),
    'slide-basic': defineAsyncComponent(() => import('./website/components/Swiper/Slides/SlideBasic.vue')),
    'slide-basic-no-link': defineAsyncComponent(() => import('./website/components/Swiper/Slides/SlideBasicNoLink.vue')),
    'slide-last-viewed': defineAsyncComponent(() => import('./website/components/Swiper/Slides/LastViewed.vue')),

    //filters
    'filter-arrival-and-stay': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/ArrivalAndStay.vue')),
    'filter-companions': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/Companions.vue')),
    'filter-destination': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/Destination.vue')),
    'filter-dropdown': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/Dropdown.vue')),
    'filter-option': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/Option.vue')),
    'filters-primary': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/PrimaryFilters.vue')),
    'filters-range-slider': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/RangeSlider.vue')),
    'filters-blocks': defineAsyncComponent(() => import('./website/components/Overview/FilterTypes/Blocks.vue')),

    //overview
    'mobile-sorting': defineAsyncComponent(() => import('./website/components/Overview/MobileSorting.vue')),
    'overview-data-handler': defineAsyncComponent(() => import('./website/components/Overview/DataHandler.vue')),
    'view-renderer-overview': defineAsyncComponent(() => import('./website/components/Overview/ViewRenderer.vue')),
    'overview-event-handler': defineAsyncComponent(() => import('./website/components/Overview/EventHandler.vue')),
    'overview-lazy-image': defineAsyncComponent(() => import('./website/components/Overview/LazyImage.vue')),
    'overview-map-host': defineAsyncComponent(() => import('./website/components/Overview/Maps/MapHost.vue')),
    'overview-maps': defineAsyncComponent(() => import('./website/components/Overview/Maps/Maps.vue')),
    'overview-map': defineAsyncComponent(() => import('./website/components/Overview/Maps/Map.vue')),
    'object-detail-trigger': defineAsyncComponent(() => import('./website/components/Overview/ObjectDetailsTrigger.vue')),
    'object-detail-popup': defineAsyncComponent(() => import('./website/components/Overview/ObjectDetailsPopup.vue')),
    'filter-portal': defineAsyncComponent(() => import('./website/components/Overview/FilterPortal.vue')),
    'alternatives-handler': defineAsyncComponent(() => import('./website/components/Overview/AlternativesHandler.vue')),

    //object details
    'collapsible-section': defineAsyncComponent(() => import('./website/components/ObjectDetails/CollapsibleSection.vue')),
    'detail-data-handler': defineAsyncComponent(() => import('./website/components/ObjectDetails/DataHandler.vue')),
    'detail-data-handler-park': defineAsyncComponent(() => import('./website/components/ObjectDetails/DataHandlerPark.vue')),
    'detail-event-handler': defineAsyncComponent(() => import('./website/components/ObjectDetails/EventHandler.vue')),
    'detail-gallery': defineAsyncComponent(() => import('./website/components/ObjectDetails/Gallery.vue')),
    'detail-maps': defineAsyncComponent(() => import('./website/components/ObjectDetails/Maps.vue')),
    'detail-share': defineAsyncComponent(() => import('./website/components/ObjectDetails/Share.vue')),
    'tabs': defineAsyncComponent(() => import('./website/components/ObjectDetails/Tabs.vue')),

    //favourites
    'favourites-event-handler': defineAsyncComponent(() => import('./website/components/Favourites/EventHandler.vue')),
    'favourites-add-to-category': defineAsyncComponent(() => import('./website/components/Favourites/AddToCategory.vue')),
    'favourites-add-to-new-category': defineAsyncComponent(() => import('./website/components/Favourites/AddToNewCategory.vue')),

    //beach huts
    'beach-huts-form': defineAsyncComponent(() => import('./website/components/BeachHuts/Form.vue')),
    'beach-huts-calendar': defineAsyncComponent(() => import('./website/components/BeachHuts/Calendar.vue')),
    'beach-huts-maps-page': defineAsyncComponent(() => import('./website/components/BeachHuts/MapsPage.vue')),
    'beach-huts-dynamic-map': defineAsyncComponent(() => import('./website/components/Booking/BeachHuts/DynamicMap.vue')),
    'inline-svg': defineAsyncComponent(() => import('vue-inline-svg')),

    //pages
    'contact-event-handler': defineAsyncComponent(() => import('./website/components/Pages/ContactEventHandler.vue')),
    'faq-full': defineAsyncComponent(() => import('./website/components/Pages/Faq/FaqFull.vue')),
    'faq-category': defineAsyncComponent(() => import('./website/components/Pages/Faq/FaqCategory.vue')),
    'job-opening-event-handler': defineAsyncComponent(() => import('./website/components/Pages/JobOpeningEventHandler.vue')),
    'rent-out-event-handler': defineAsyncComponent(() => import('./website/components/Pages/RentOutEventHandler.vue')),
    'misc-event-handler': defineAsyncComponent(() => import('./website/components/Pages/MiscEventHandler.vue')),

    // Reviews
    'form-review': defineAsyncComponent(() => import('./website/components/Reviews/ReviewStars.vue')),
    'nps-selector': defineAsyncComponent(() => import('./website/components/Reviews/NPSSelection.vue')),

    // Payments
    'payment-method-host': defineAsyncComponent(() => import('./website/components/Payments/PaymentMethodHost.vue')),
    'payment-term-item': defineAsyncComponent(() => import('./website/components/Payments/PaymentTermItem.vue')),

    // Booking
    'booking-page-host': defineAsyncComponent(() => import('./website/components/Booking/BookingPageHost.vue')),
};

/** Initialize app **/
const app = createApp({
   //
});

/** Register components **/
for (const [name, component] of Object.entries(components)) {
    app.component(name, component);
}

/** Register plugins **/
// app.use(LocalizationPlugin);
app.use(MittPlugin);

// Plugins
app.directive('click-outside', OutsideClick);

/**
 * Attach global properties for Vue
 */
// app.config.globalProperties.l_ = window.l_;
// app.config.globalProperties.__ = window.__;
// app.config.globalProperties.___ = window.___;
// app.config.globalProperties.img_webp = window.img_webp;

/** Mount app **/
app.mount("#root")

/**
 * Initialize Axios
 */
window.axios = axios;
window.axios.defaults.headers["common"]['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Initialize lozad
 */
// window.lozad = lozad;

/**
 * Initialize ouibounce
 */
// window.ouibounce = ouibounce;
